import { styled } from '@abyss/web/tools/styled';
import { Badge } from '@abyss/web/ui/Badge';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';

export const MyPCPContainer = styled(Layout.Stack, {
  minWidth: '405px',

  '@screen < $sm': {
    minWidth: '100%',
  },

  '.abyss-c-jKtYub': {
    marginBottom: '0px',
  },
});

export const DependentsContainer = styled(Layout.Stack, {
  variants: {
    memberInfo: {
      true: {
        width: 'calc(100% - 435px)',
        '@media (max-width: 853px)': { width: '100%' },
      },
      false: {
        width: 'calc(100% - 540px)',
        '@media (max-width: 943px)': { width: '100%' },
      },
    },
  },
  '> span ': {
    width: '100%',
    maxWidth: '100%',
  },

  '.abyss-c-PJLV': {
    width: '100%',
    margin: '0px',
  },
});

export const MyPCPCardContainer = styled('div', {
  dynamic: ({ cssProps: { displayOONMessage } }) => ({
    height: 'auto',
    '.abyss-card-root': {
      border: displayOONMessage ? '1px solid $warning1' : '1px solid $gray3',
      height: 'auto',
    },
    '.abyss-card-section': {
      backgroundColor: displayOONMessage ? '$warning2' : '$white',
      height: 'auto',
    },
    margin: 0,

    width: '407px',
  }),
  margin: '8px 24px 0px 0',

  '@screen < $sm': {
    maxWidth: '342px',
    width: '100%',
  },
  '.abyss-layout-group': {
    '.abyss-c-fAMcLx': { width: '24px', height: '24px' },
    justifyContent: 'space-between',
  },

  '.abyss-card-root': {
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
  },
  '.abyss-card-section': {
    borderRadius: '8px',
    padding: '12px 16px',
  },
});

export const MyPCPInfoContainer = styled(Layout.Group, {
  height: 'auto',
});

export const StyledViewIdCardBox = styled(Box, {
  borderRadius: '4px',
  marginTop: '8px',
});

export const StyledViewIdCardGroup = styled(Layout.Group, {
  '&.abyss-layout-group': {
    justifyContent: 'flex-start',
  },
});

export const StyledViewIdCardStack = styled(Layout.Stack, {
  lineHeight: '18px',
});

export const MyPCPInfo = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

export const MyPCPAvatar = styled(Flex, {
  width: '56px',
  height: '56px',
  minWidth: '56px',
  minHeight: '56px',
  borderRadius: '50%',
  overflow: 'hidden',
  marginRight: '8px',
});

export const MyPCPText = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '20px',
  textAlign: 'left',
});

export const MyPCPEffectiveBadge = styled(Badge, {
  '&.abyss-badge-root': {
    maxWidth: 'none',
  },

  '&.abyss-badge-content': {
    overflow: 'visible',
  },
});

export const EditPCPPopover = styled(Popover, {
  zIndex: '2000 !important',
  '&.abyss-popover-trigger': {
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
  },
  '&.abyss-popover-title': {
    fontSize: '$xl !important',
    fontWeight: 'bold !important',
  },

  '&.abyss-popover-content-container': {
    overflow: 'hidden',
  },
});

export const EditPCPPopoverContainer = styled('div', {
  width: '10px',
  '> div': {
    zIndex: '10 !important',
  },
});

export const EditPCPPopoverButton = styled(Button, {
  '&.abyss-button-root': {
    paddingLeft: '0',
    '&:focus-visible': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
});

export const EditPCPPopoverButtonText = styled(Text, {
  paddingLeft: '$sm',
});

export const editPCPMenuStyle = {
  width: '72px',
  height: '18px',
  radius: '24px',
  border: 'none',
  backgroundColor: '#E5F8FB',
  padding: '0',
  '.abyss-badge-root': {
    padding: '0',
  },
  '.abyss-badge-content': {
    fontSize: '12.64px',
    fontWeight: '700',
    fontStyle: 'normal',
    lineHeight: '16px',
    color: '#196ECF',
  },
  '.abyss-icon-material': {
    marginRight: '4px',
  },
};

export const ChoosePhotoPlusIcon = styled(IconMaterial, {
  position: 'absolute',
  left: '57%',
});

export const ChoosePhotoContainer = styled('div', {
  position: 'relative',
  display: 'inline-block',
  verticalAlign: 'middle',
});
export const ChoosePCPPhoto = styled(Flex, {
  '&.abyss-flex-root': {
    color: '#CBCCCD',
    marginTop: '8px',
    width: '45px',
    height: '45px',
    minWidth: '45px',
    minHeight: '45px',
    borderRadius: '50%',
    overflow: 'hidden',
    marginRight: '8px',
    '@screen < $sm': {
      width: '46.67px',
      height: '46.67px',
      minWidth: '46.67px',
      minHeight: '46.67px',
    },
  },
});

export const PopoverContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '$sm',
  gap: '20px',
  boxShadow: '0 4px 4px 0',
});

export const PopoverHeading = styled('div', {
  marginLeft: '$sm',
  fontWeight: '$semibold',
  fontSize: '14px',
  fontStyle: 'normal',
  lineHeight: '18px',
  color: '$foreground',
  marginBottom: '0',
});

export const OONPopoverContainer = styled('div', {
  '.abyss-popover-content': {
    zIndex: '9 !important',
  },
});

export const PopoverSubHeading = styled('div', {
  fontWeight: '$semibold',
  fontSize: '$md',
  lineHeight: '20px',
  color: '$neutralGray7',
  marginBottom: '$sm',
});

export const PopoverList = styled('ul', {
  listStyleType: 'disc',
  listStylePosition: 'outside',
  paddingLeft: '10px',
  marginLeft: '$md',
});

export const PopoverContent = styled('div', {
  fontWeight: '$medium',
  fontSize: '$md',
  lineHeight: '20px',
  color: '$neutralGray',
});

export const PopoverOutOfNetworkContent = styled('div', {
  fontWeight: '$normal',
  fontSize: '$sm',
  lineHeight: '18px',
  color: '$warning1',
  marginBottom: '5px',
});

export const ChooseInNetworkPCP = styled('div', {
  fontWeight: '$normal',
  fontSize: '$sm',
  color: '$foreground',
  lineHeight: '18px',
});

export const ChooseNewPCPButton = styled(Button, {
  fontSize: '$md',
  fontWeight: '$semibold',
});

export const PopoverFlex = styled(Flex, {
  marginBottom: '11px',
  marginTop: '3px',
});

export const linkStyles = {
  width: '136px',
  height: '20px',
  display: 'flex',
  '&.abyss-link-root': {
    fontWeight: 'bold',
    fontSize: '$md',
    lineHeight: '20px',
    alignItems: 'center',
    textAlign: 'center',
  },
  marginLeft: '$sm',
  marginTop: '2px',
};

export const ChoosePCPHeadingContainer = styled('div', {
  paddingRight: '24px',
  fontWeight: '$bold',
  fontSize: '25.63px',
  lineHeight: '32px',
  color: '$primary1',
  '@screen < $md': {
    fontSize: '$mediumText !important',
  },
  '@screen < $sm': {
    fontSize: '$lg',
    lineHeight: '24px',
  },
});

export const YourPCPHeaderMobile = styled('div', {
  width: '279px',
  height: '24px',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '20.25px',
  lineHeight: '24px',
  color: '#323334',
  marginLeft: '24px',
});

export const PCPDrawerSection = styled('span', {
  height: '20px',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '20px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  color: '#196ECF',
  flex: 'none',
  order: '1',
  flexGrow: '0',
});

export const PCPDrawerMobile = {
  'abyss-modal-close-button': {
    marginTop: '8px',
    marginRight: '$lg',
  },
  'abyss-modal-header-container': {
    padding: '16px',
  },
  'abyss-modal-content-container': {
    borderRadius: '20px 20px 0px 0px',
    height: 'auto !important',
  },
  'abyss-modal-content-title': {
    padding: 0,
  },
  'abyss-modal-body': {
    padding: '0 16px 16px',
  },
};

export const OONContainer = styled('div', {
  display: 'flex',
  margin: '8px 0 0 0',

  '>div': {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
});

export const StyledOONMessage = styled('span', {
  color: '$warning1',
  display: 'inline-block',
  fontSize: '14.22px',
  fontWeight: '$bold',
  maxWidth: '342px',
  paddingRight: '4px',
  paddingLeft: '4px',
  lineHeight: '20px',
  verticalAlign: 'middle',
});

export const DrawerOutOfNetworkContent = styled('div', {
  fontWeight: '$bold',
  fontSize: '$md',
  lineHeight: '20px',
  color: '$warning1',
  marginBottom: '8px',
});

export const ChooseInNetworkPCPMobileView = styled('div', {
  fontWeight: '$medium',
  fontSize: '$md',
  lineHeight: '20px',
  color: '$gray7',
  marginBottom: '20px',
});

export const OONMessageDrawer = {
  'abyss-modal-close-button': {
    paddingBottom: '13px',
    paddingRight: '15px',
  },
  'abyss-modal-content-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: '20px 20px 0px 0px',
    height: 'unset',
    boxShadow: '0px -4px 12px rgba(25, 25, 26, 0.32)',
    padding: '16px 24px',
    gap: '24px',
  },
  'abyss-modal-header-container': {
    padding: 0,
  },
  'abyss-modal-content-title': {
    padding: '0 !important',
    fontSize: '1.266rem !important',
    color: '$gray8 !important',
    fontWeight: '$bold !important',
    lineHeight: '24px !important',
    fontFamily: '$primary !important',
  },
};

export const ViewAllDrawer = styled(Drawer, {
  '&.abyss-modal-close-button': {
    margin: '18px 6px 0 0',
  },
  '&.abyss-modal-content-container': {
    width: '408px',
    height: '100%',
    '@screen < $sm': {
      width: 'auto',
      height: 'auto !important',
    },
    '@screen <= $md': {
      height: '100%',
    },
    '@screen <= $lg': {
      height: '100%',
    },
  },
  '&.abyss-modal-header-container': {
    padding: '16px',
  },
  '&.abyss-modal-content-title': {
    color: '#323334 !important',
    paddingTop: '0',
    fontSize: '25.63px !important',
    fontWeight: '700 !important',
    '@screen < $sm': {
      fontSize: '20.25px !important',
    },
  },
  '&.abyss-modal-body': {
    overflow: 'hidden',
  },
  '&.abyss-card-section': {
    minWidth: '380px',
  },
});

export const AlertMessage = {
  backgroundColor: '#EDF3FB',
};
export const PCPAlertMessage = {
  '.abyss-alert-container': {
    borderColor: '$white',
    minHeight: '36px !important',
    borderWidth: '0px !important',
    border: 0,
    padding: '0px',
  },
  '.abyss-alert-icon-container': {
    marginTop: '-6px',
    height: '20px',
    width: '20px',
  },
};

export const PCPAlertMessageMobile = {
  '.abyss-alert-container': {
    borderColor: '$white',
    minHeight: '36px !important',
    borderWidth: '0px !important',
    padding: '0px',
  },
  '.abyss-alert-icon-container': {
    marginTop: '-20px',
    height: '16.67px',
    width: '16.67px',
  },
  '.abyss-alert-title': {
    fontSize: '14px',
  },
};

export const TerminationContainer = styled('div', {
  dynamic: ({ cssProps: { isMnr } }) => ({
    border: '1px, 1px, 1px, 4px',
    padding: '8px, 8px, 8px, 12px',

    '.abyss-alert-container': {
      border: '0',
      margin: '8px 0 0 0',
    },

    '.abyss-icon-material': {
      width: '20px',
      height: '20px',
    },

    '.abyss-alert-title': {
      margin: 0,
      fontSize: '$md',
      fontWeight: '$bold',

      '@screen < $sm': {
        fontSize: '$sm',
      },
    },

    '.abyss-alert-children-container': {
      fontSize: '$sm',
      fontWeight: '$normal',
      display: isMnr && 'grid',
    },

    '.abyss-link-root': {
      fontSize: '$sm',
      fontWeight: '$semibold',
    },
  }),
});
