import { StoreKeys } from '../../hooks/useStore/state';
import { useStore } from '../../hooks/useStore/useStore';

export const showUpcomingAvailabilityOption = (
  showUpcomingAvailabilityFF: boolean,
  isPreEffective: boolean
): boolean => {
  const resultsHasProviderWithUpcomingAvailability = useStore(
    StoreKeys.RESULTS_HAS_PROVIDER_WITH_UPCOMING_AVAILABILITY
  );

  return (
    resultsHasProviderWithUpcomingAvailability &&
    showUpcomingAvailabilityFF &&
    !isPreEffective
  );
};
