import find from 'lodash/find';
import React from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useLagoon } from '../../hooks/useLagoon';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import { isDentalNetworkIdNotAvailable } from '../../utils/DentalProviderNotAvailableCard.utils';
import { getCurrentMember, isOHCNSMember } from '../../utils/user.utils';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { isMnrMember } from '../Utils/memberUtils/memberUtils';
import { CNSOHDisclaimersContainer } from './CNSOHDisclaimerContainer';
import { ENIDisclaimersContainer } from './ENIDisclaimersContainer';
import { MNRDisclaimersContainer } from './MNRDisclaimersContainer';

interface HGProps {
  showPremiumCareDisclaimer?: boolean;
  isVisionCare?: boolean;
  ratingSection?: boolean;
  isMNR?: boolean;
  isViewAllDisclaimer?: boolean;
  isLandingPage?: boolean;
}

export const DisclaimersContainer = ({
  showPremiumCareDisclaimer = false,
  isVisionCare = false,
  ratingSection = false,
  isViewAllDisclaimer = false,
  isLandingPage = false,
}: HGProps) => {
  const { lineOfBusiness = '' } = getCurrentMember();
  const isMNR = isMnrMember(lineOfBusiness);
  const lagoonData = useLagoon('ui-messaging')();
  const OHCNSpolicyIds = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.STATE_SPECIFIC_DISCLAIMER,
  })?.policyIDs;

  const isOHCNS = isOHCNSMember(OHCNSpolicyIds);

  const { chipValue } = useChipStore(
    useShallow((state: ChipState) => ({
      chipValue: state.chipValue,
    }))
  );

  const currentMember = getCurrentMember();

  const isDentalProvidersNotAvailable = isDentalNetworkIdNotAvailable(
    chipValue,
    currentMember
  );
  const { search, sectionType = '' } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      sectionType: state.typeaheadSearchStore.sectionType,
      search: state.typeaheadSearchStore.search,
    }))
  );

  if (isDentalProvidersNotAvailable) {
    return null;
  }

  if (isOHCNS) {
    return (
      <CNSOHDisclaimersContainer
        search={search ?? ''}
        sectionType={sectionType}
      />
    );
  }

  return isMNR ? (
    <MNRDisclaimersContainer
      isLandingPage={isLandingPage}
      isViewAllDisclaimer={isViewAllDisclaimer}
      ratingSection={ratingSection}
      search={search ?? ''}
      sectionType={sectionType}
      selectedChipValue={chipValue}
    />
  ) : (
    <ENIDisclaimersContainer
      isVisionCare={isVisionCare}
      ratingSection={ratingSection}
      search={search ?? ''}
      sectionType={sectionType}
      selectedChipValue={chipValue}
      showPremiumCareDisclaimer={showPremiumCareDisclaimer}
    />
  );
};
