import { config } from '@abyss/web/tools/config';
import { useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import {
  CareCategories,
  Constants,
  CoverageTypesCodes,
  OPTUM_CORRELATION_ID_HEADER,
  ReverseCoverageTypesCodes,
} from '../../common/Constants';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../common/Utils';
import { getLanguage } from '../../frontends/ProviderSearch/context/Internationalization/helpers';
import { useCurrentMemberIsPreEffective } from '../../hooks/useCurrentMemberIsPreEffective';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import { supportNewCodes } from '../../utils/common';
import {
  getLob,
  getMemberHealthCoverageType,
} from '../../utils/providerSearch.utils';
import {
  getClaimOrEligibilitySystemTypeCode,
  getCoverageEffDateRange,
  getCurrentMember,
  getCurrentPlanYear,
  getMemberEffectivePlanYear,
  getPlanVariationCode,
  getRulesPackageKey,
} from '../../utils/user.utils';
import { useCustomQuery } from '../useCustomQuery';
import { useGeoLocationStorage } from '../useGeoLocationStorage';
import { useLagoon } from '../useLagoon';
import CATEGORY_COUNT from './CategoriesCount.graphql';

export const useCategoryCountSearch = (options) => {
  const [categoryCount, setCategoryCount] = useState({} as any);
  const [queryResult, queryAction] = useCustomQuery(CATEGORY_COUNT, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'getChipCategoriesCount',
    onCompleted: (result) => {
      if (options?.onCompleted) {
        options.onCompleted(result);
      }
      setCategoryCount(result);
    },
  });

  const parsedResult = {
    ...queryResult,
    data: categoryCount?.data || {},
    corelationId: categoryCount?.headers?.[OPTUM_CORRELATION_ID_HEADER] || '',
  };

  return [parsedResult, queryAction];
};
export const getIncludeSpecialityRollupCodes = (
  eachCategory,
  useMedicalNewRollUpCodes,
  useBHNewRollUpCodes,
  useVisionNewRollUpCodes,
  useDentalNewRollUpCodes,
  rollupCodeMap
) => [
  ...new Set(
    eachCategory.includeSpecialityRollupCodes.map((code) => {
      const useNewRollupCode = supportNewCodes({
        coverageType: eachCategory.coverages[0],
        useMedicalNewRollUpCodes,
        useBHNewRollUpCodes,
        useVisionNewRollUpCodes,
        useDentalNewRollUpCodes,
      });
      return useNewRollupCode
        ? code?.trim()
        : rollupCodeMap[code?.trim()] || code?.trim();
    })
  ),
];
const getBehavioralFacilityPrograms = (
  eachCategory,
  bhFpProgramToggleOn,
  bhProgramFacilityAgg
) =>
  eachCategory.label === CareCategories.BEHAVIORAL_HEALTH && bhFpProgramToggleOn
    ? bhProgramFacilityAgg
    : null;

export const useCategoriesCount = (options = {}) => {
  const locale = getLanguage()?.code || 'en';
  const { longitude, latitude, stateCode } = useGeoLocationStorage();

  const currentMember = getCurrentMember();
  const medicalCoverage = getMemberHealthCoverageType(
    currentMember?.eligibility,
    CoverageTypesCodes.MEDICAL
  );

  const { coverageType, storeLob } = useChipStore(
    useShallow((state: ChipState) => ({
      coverageType: state.coverageType,
      storeLob: state.lob,
    }))
  );

  const { bhProgramFacilityAgg } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      bhProgramFacilityAgg: state.typeaheadSearchStore.bhProgramFacilityAgg,
    }))
  );

  const [categoryCount, getCategoryCountDetail] =
    useCategoryCountSearch(options);

  const rollupCodeMapLagoonData = useLagoon(
    Constants.LAGOON_TABLE.ROLLUPCODE_MAPPING
  )();
  const rollupCodeMap = rollupCodeMapLagoonData?.reduce((acc, item) => {
    if (item.source && item.destination) {
      acc[item.source] = item.destination;
    }
    return acc;
  }, {});

  const tierBenefits = useLagoon('tier-benefit')();
  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();

  const useMedicalNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_MEDICAL_NEW_ROLLUP_CODES
  );
  const useBHNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_BH_NEW_ROLLUP_CODES
  );
  const useVisionNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_VISION_NEW_ROLLUP_CODES
  );
  const useDentalNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_DENTAL_NEW_ROLLUP_CODES
  );

  const uspTierOneFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_TIER1_ENABLE
  );

  const uspToggleFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_ENABLE
  );

  const bhFpProgramToggleOn: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_BH_FACILITIES_PROGRAM_SEARCH
  );

  const memCatergory = currentMember?.membershipCategory || '';
  const membershipCategory = uspToggleFlag ? memCatergory : null;
  const isPreEffective = useCurrentMemberIsPreEffective();
  const currentMemberEffectiveDate =
    getCoverageEffDateRange(currentMember).startDate;

  const getCategoryCountResults = (passedParams) => {
    getCategoryCountDetail({
      variables: {
        locale,
        providerType: '',
        policyID: currentMember?.policyNumber,
        planYear: !isPreEffective
          ? getCurrentPlanYear()
          : getMemberEffectivePlanYear(new Date(currentMemberEffectiveDate)),
        memberEffectiveDate: isPreEffective ? currentMemberEffectiveDate : null,
        lob: getLob(currentMember?.lineOfBusiness, storeLob),
        population: currentMember?.population,
        membershipCategory,
        rulesPackageKey: getRulesPackageKey(
          currentMember,
          tierBenefits,
          '',
          uspTierOneFlag
        ),
        latitude,
        longitude,
        stateCode,
        ...(passedParams?.gender && {
          gender: passedParams?.gender,
        }),
        ...(passedParams?.virtualCare && {
          virtualCare: passedParams?.virtualCare,
        }),
        planVariationCode: getPlanVariationCode(currentMember, coverageType),
        claimSystemTypeCode: getClaimOrEligibilitySystemTypeCode(
          currentMember,
          coverageType
        )?.claimSystemTypeCode,
        eligibilitySystemTypeCode: getClaimOrEligibilitySystemTypeCode(
          currentMember,
          coverageType
        )?.eligibilitySystemTypeCode,
        searchRadius: passedParams?.searchRadius,
        autoIncrementRadius: passedParams?.autoIncrementRadius,
        pseudoRollupCode: passedParams?.pseudoRollupCode,
        preferredFacilities: passedParams?.preferredFacilities,
        snpType:
          coverageType == ReverseCoverageTypesCodes.MEDICAL
            ? medicalCoverage?.snpType
            : '',
        searchTerm: passedParams?.searchTerm,
        invokeLlmModel: passedParams?.invokeLlmModel,
        isUserTextSearch: passedParams?.isUserTextSearch,
        bhFpProgramToggleOn,

        categoryRecords: passedParams.payloadwithName.map((eachCategory) => ({
          ...eachCategory,
          behavioralFacilityPrograms: getBehavioralFacilityPrograms(
            eachCategory,
            bhFpProgramToggleOn,
            bhProgramFacilityAgg
          ),
          uesType: undefined,
          aoeCodes: passedParams?.aoeCodes,
          prioritizeAoe: passedParams?.prioritizeAoe,
          includeSpecialityRollupCodes: getIncludeSpecialityRollupCodes(
            eachCategory,
            useMedicalNewRollUpCodes,
            useBHNewRollUpCodes,
            useVisionNewRollUpCodes,
            useDentalNewRollUpCodes,
            rollupCodeMap
          ),
          useMedicalNewRollUpCodes,
          useBHNewRollUpCodes,
          useVisionNewRollUpCodes,
          useDentalNewRollUpCodes,
        })),
      },
    });
  };

  return [categoryCount, getCategoryCountResults];
};
